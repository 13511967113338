import React, { FC } from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const AmericanExpressIcon: FC<CustomIconProps> = ({
    className,
    fontSize,
    width = '48px',
    height = '30px',
    ...props
}) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg {...props} width="100%" height="100%" viewBox="0 0 48 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>American Express Icon</title>

            <g clipPath="url(#clip0_6152_107171)">
                <path
                    d="M44.0797 26.72L41.9997 24.4L39.8397 26.72H26.4798V15.92H22.2398L27.5198 3.84H32.6397L34.4797 8V3.84H40.8797L41.9997 6.96L43.1197 3.84H47.9996V2C47.9996 0.88 47.1196 0 45.9996 0H1.99998C0.879993 0 0 0.88 0 2V28C0 29.12 0.879993 30 1.99998 30H45.9996C47.1196 30 47.9996 29.12 47.9996 28V26.72H44.0797Z"
                    fill="#0071CE"
                />
                <path
                    d="M44.6397 25.4401H47.9996L43.5997 20.7201L47.9996 16.0801H44.7197L41.9197 19.1201L39.1997 16.0801H35.8397L40.3197 20.8001L35.8397 25.4401H39.1197L41.9197 22.4001L44.6397 25.4401Z"
                    fill="#0071CE"
                />
                <path
                    d="M30.4798 23.2801V21.8401H35.7597V19.6801H30.4798V18.2401H35.8397V16.0801H27.9198V25.4401H35.8397V23.2801H30.4798Z"
                    fill="#0071CE"
                />
                <path
                    d="M45.5197 14.72H47.9196V5.28003L44.0797 5.36003L41.9997 11.2L39.8397 5.36003H35.8397V14.72H38.3997V8.16003L40.7997 14.72H43.0397L45.5197 8.16003V14.72Z"
                    fill="#0071CE"
                />
                <path
                    d="M31.7598 5.35999H28.4798L24.3198 14.72H27.1198L27.9198 12.88H32.2398L33.0397 14.72H35.9197L31.7598 5.35999ZM28.7998 10.72L30.0798 7.67999L31.3598 10.72H28.7998Z"
                    fill="#0071CE"
                />
                <path d="M45.6796 20.48L47.9996 23.04V17.92L45.6796 20.48Z" fill="#0071CE" />
            </g>
            <defs>
                <clipPath id="clip0_6152_107171">
                    <rect width="48" height="30" rx="2" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </SVGWrapper>
)

export default AmericanExpressIcon
