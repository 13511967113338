import React from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const CarIcon: React.FC<CustomIconProps> = ({ className, fontSize, width = '24px', height = '24px', ...props }) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg
            data-testid="car-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            {...props}
        >
            <mask
                id="a"
                width={width}
                height={height}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'alpha',
                }}
            >
                <path fill="#D9D9D9" d="M0 0h24v24H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="#1C1B1F"
                    d="M5.615 18.5v.942c0 .294-.103.544-.308.75a1.022 1.022 0 0 1-.75.308c-.295 0-.544-.103-.75-.308a1.021 1.021 0 0 1-.307-.75V12.39c0-.104.007-.208.02-.312.014-.104.036-.203.068-.299l1.79-5.063c.12-.36.338-.652.652-.877A1.772 1.772 0 0 1 7.087 5.5h9.826c.39 0 .742.113 1.056.338.315.225.532.517.652.877l1.79 5.063c.033.096.055.195.069.299.013.104.02.208.02.312v7.053c0 .294-.103.544-.309.75a1.022 1.022 0 0 1-.75.308c-.294 0-.544-.103-.749-.308a1.022 1.022 0 0 1-.307-.75V18.5H5.615Zm-.007-7.923h12.784l-1.184-3.375a.32.32 0 0 0-.116-.15A.315.315 0 0 0 16.91 7H7.09a.314.314 0 0 0-.182.053.319.319 0 0 0-.116.149l-1.184 3.375Zm1.856 5.27c.364 0 .672-.128.925-.383.254-.255.38-.564.38-.928 0-.364-.127-.672-.382-.925a1.267 1.267 0 0 0-.928-.38c-.364 0-.672.127-.925.382-.254.255-.38.564-.38.928 0 .364.127.672.382.925.255.254.564.38.928.38Zm9.077 0c.364 0 .672-.128.925-.383s.38-.564.38-.928c0-.364-.127-.672-.382-.925a1.267 1.267 0 0 0-.928-.38c-.364 0-.672.127-.925.382-.254.255-.38.564-.38.928 0 .364.127.672.382.925.255.254.564.38.928.38ZM5 17h14v-4.923H5V17Z"
                />
            </g>
        </svg>
    </SVGWrapper>
)

export default CarIcon
