import React from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const SuitesIcon: React.FC<CustomIconProps> = ({ className, fontSize, width = '24px', height = '24px', ...props }) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg
            data-testid="suites-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            {...props}
        >
            <mask
                id="a"
                width={24}
                height={24}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'alpha',
                }}
            >
                <path fill="#D9D9D9" d="M0 0h24v24H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="#1C1B1F"
                    d="M4.308 21.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.283V4.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h15.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v15.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H4.308Zm0-1.5h15.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.212V4.308a.294.294 0 0 0-.096-.212.294.294 0 0 0-.212-.096H4.308a.294.294 0 0 0-.212.096.294.294 0 0 0-.096.212v15.384c0 .077.032.148.096.212a.294.294 0 0 0 .212.096ZM7 17.692h10c.55 0 .97-.168 1.259-.506.289-.337.433-.732.433-1.186v-3.692a2.08 2.08 0 0 0-.347-1.166 2.182 2.182 0 0 0-.903-.792V9.192c0-.743-.263-1.378-.788-1.904A2.594 2.594 0 0 0 14.75 6.5h-5.5c-.744 0-1.378.263-1.904.788a2.594 2.594 0 0 0-.788 1.904v1.158c-.37.176-.672.44-.903.792a2.08 2.08 0 0 0-.347 1.166V16c0 .55.168.97.505 1.259.338.289.733.433 1.187.433ZM7 16.5a.49.49 0 0 1-.363-.137A.49.49 0 0 1 6.5 16v-3.692c0-.284.096-.521.287-.713a.967.967 0 0 1 .713-.287c.283 0 .52.095.712.287.192.192.288.43.288.713v2h7v-2c0-.284.096-.521.287-.713a.967.967 0 0 1 .713-.287c.283 0 .52.095.712.287.192.192.288.43.288.713V16a.49.49 0 0 1-.137.363.49.49 0 0 1-.363.137H7Zm2.692-3.385v-.807c0-.573-.19-1.063-.568-1.469a2.05 2.05 0 0 0-1.374-.674v-.973c0-.433.142-.791.425-1.075.283-.283.642-.425 1.075-.425h5.5c.433 0 .792.142 1.075.425.283.284.425.642.425 1.075v.973a2.05 2.05 0 0 0-1.374.674 2.075 2.075 0 0 0-.568 1.469v.807H9.692Z"
                />
            </g>
        </svg>
    </SVGWrapper>
)

export default SuitesIcon
