import React, { FC } from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const BasicDeliveryIcon: FC<CustomIconProps> = ({ className, fontSize, width = '20px', height = '20px', ...props }) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg {...props} width="100%" height="100%" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 11.8125C21 12.1232 20.7482 12.375 20.4375 12.375C20.1268 12.375 19.875 12.1232 19.875 11.8125V6.1875C19.875 5.87684 19.6232 5.625 19.3125 5.625H10.3125C10.0018 5.625 9.75 5.87684 9.75 6.1875V7.76552V10.6875V11.478C9.75 11.7887 9.49816 12.0405 9.1875 12.0405C8.87684 12.0405 8.625 11.7887 8.625 11.478V10.6875V8.32802H6.9375C5.36287 8.32802 4.125 9.34926 4.125 10.5499V14.2624C4.125 14.4378 4.35551 14.628 4.6875 14.628H5.25C5.56066 14.628 5.8125 14.8799 5.8125 15.1905C5.8125 15.5012 5.56066 15.753 5.25 15.753H4.6875C3.77685 15.753 3 15.1121 3 14.2624V10.5499C3 8.67499 4.78421 7.20302 6.9375 7.20302H8.625V6.1875C8.625 5.25552 9.38052 4.5 10.3125 4.5H19.3125C20.2445 4.5 21 5.25552 21 6.1875V11.8125ZM8.625 16.875C7.38236 16.875 6.375 15.8676 6.375 14.625C6.375 13.3824 7.38236 12.375 8.625 12.375C9.86764 12.375 10.875 13.3824 10.875 14.625C10.875 15.8676 9.86764 16.875 8.625 16.875ZM8.625 15.75C9.24632 15.75 9.75 15.2463 9.75 14.625C9.75 14.0037 9.24632 13.5 8.625 13.5C8.00368 13.5 7.5 14.0037 7.5 14.625C7.5 15.2463 8.00368 15.75 8.625 15.75ZM15.375 14.625C15.375 15.8676 16.3824 16.875 17.625 16.875C18.8676 16.875 19.875 15.8676 19.875 14.625C19.875 13.3824 18.8676 12.375 17.625 12.375C16.3824 12.375 15.375 13.3824 15.375 14.625ZM18.75 14.625C18.75 15.2463 18.2463 15.75 17.625 15.75C17.0037 15.75 16.5 15.2463 16.5 14.625C16.5 14.0037 17.0037 13.5 17.625 13.5C18.2463 13.5 18.75 14.0037 18.75 14.625ZM11.4375 15.1875C11.4375 15.4982 11.6893 15.75 12 15.75H14.25C14.5607 15.75 14.8125 15.4982 14.8125 15.1875C14.8125 14.8768 14.5607 14.625 14.25 14.625H12C11.6893 14.625 11.4375 14.8768 11.4375 15.1875Z"
                fill="#1C94A5"
            />
        </svg>
    </SVGWrapper>
)

export default BasicDeliveryIcon
