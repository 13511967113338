import React, { FC } from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const OpponentIcon: FC<CustomIconProps> = ({ className, fontSize, width, height, color = '#000', ...props }) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg
            {...props}
            width="100%"
            height="100%"
            viewBox="0 0 14 10"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            color={color}
        >
            <title>Opponent Icon</title>
            <g fill="currentColor" fillRule="evenodd">
                <path
                    d="M11.0311 8.08895C10.4195 8.08895 9.86917 7.98332 9.37994 7.77207C8.90183 7.54969 8.52379 7.24948 8.24582 6.87144C7.97897 6.48228 7.84554 6.0542 7.84554 5.58721H9.27987C9.27987 5.97637 9.44665 6.29882 9.78022 6.55455C10.1138 6.79917 10.5585 6.92147 11.1145 6.92147C11.5481 6.92147 11.8928 6.8214 12.1485 6.62126C12.4043 6.42113 12.5321 6.15427 12.5321 5.82071C12.5321 5.54274 12.4487 5.32036 12.282 5.15358C12.1152 4.97568 11.815 4.83113 11.3813 4.71994L9.81357 4.31967C9.24651 4.164 8.81288 3.90827 8.51267 3.55247C8.22358 3.18555 8.07904 2.73523 8.07904 2.20153C8.07904 1.55664 8.34589 1.02849 8.87959 0.617095C9.42441 0.205698 10.1138 0 10.9477 0C11.8594 0 12.5766 0.205698 13.0992 0.617095C13.6329 1.02849 13.8997 1.59555 13.8997 2.31828H12.4821C12.4821 1.95136 12.3431 1.66227 12.0651 1.45101C11.7872 1.22863 11.398 1.11744 10.8977 1.11744C10.4863 1.11744 10.1527 1.21195 9.89697 1.40097C9.64123 1.57887 9.51336 1.82349 9.51336 2.13482C9.51336 2.39055 9.59676 2.60737 9.76354 2.78527C9.94144 2.95205 10.2305 3.09104 10.6308 3.20222L12.2152 3.63586C12.7823 3.79152 13.2215 4.05281 13.5328 4.41974C13.8442 4.78666 13.9998 5.23697 13.9998 5.77067C13.9998 6.4934 13.733 7.06046 13.1993 7.47186C12.6656 7.88325 11.9428 8.08895 11.0311 8.08895Z"
                    fill={color}
                />
                <path d="M3.35176 4.46622L1.72304 0.591H0.171968L2.62151 6.15377L3.35176 4.46622Z" fill={color} />
                <path
                    d="M3.38552 7.88887H4.56962L7.95531 0.200195H6.40429L3.96926 5.95419L3.23178 7.53973L3.38552 7.88887Z"
                    fill={color}
                />
                <path d="M0.499837 9H13.9998V10H0.499837V9Z" fill={color} />
            </g>
        </svg>
    </SVGWrapper>
)

export default OpponentIcon
