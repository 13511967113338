import React, { useMemo } from 'react'

import Head from 'next/head'

import type { Metadata, TrailingSlashBehavior } from '@/types/app-types'

/**
 * Converts img string URL to an https://xyz format
 * @param imageUrl string to adjust
 * @returns string in valid format for meta tag
 */
const prependHTTPS = (imageUrl: string): string => {
    // "https://abc.com"
    if (imageUrl.startsWith('http')) {
        return imageUrl
    }
    // "//abc.com"
    if (imageUrl.startsWith('//')) {
        return `https:${imageUrl}`
    }
    // "abc.com"
    return `https://${imageUrl}`
}

const handleTrailingSlash = (url: string, trailingSlashBehavior: TrailingSlashBehavior): string => {
    switch (trailingSlashBehavior) {
        case 'never': {
            // Always remove trailing slash from the URL
            if (/\/$/.test(url)) {
                return url.slice(0, -1)
            }
            break
        }
        case 'always': {
            // Always add trailing slash to the URL
            if (!/\/$/.test(url)) {
                return `${url}/`
            }
            break
        }
    }

    // Return unmodified URL
    return url
}

const Meta: React.FC<Metadata> = ({
    description,
    image,
    keywords,
    title,
    url,
    canonicalEnabled = true,
    trailingSlashBehavior = 'never',
}) => {
    const processedUrl: string | undefined = useMemo(
        () => (url ? handleTrailingSlash(url, trailingSlashBehavior) : undefined),
        [url, trailingSlashBehavior],
    )

    return (
        <Head>
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description} />}
            {keywords && <meta name="keywords" content={keywords} />}
            {processedUrl && canonicalEnabled && <link rel="canonical" href={processedUrl} />}
            {image ? (
                <meta property="og:image" content={prependHTTPS(image)} key="ogimage" />
            ) : (
                <meta property="og:image" content="http://a.vsstatic.com/common/logo/900x425-logo.jpg" key="ogimage" />
            )}
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" key="viewport" />
            <meta name="application-name" content="Vivid Seats" />
            <meta name="apple-mobile-web-app-title" content="Vivid Seats" />
            <meta name="msapplication-TileColor" content="#333333" />
            <meta name="msapplication-config" content="//a.vsstatic.com/common/favicon/browserconfig.xml" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="google-play-app" content="app-id=com.vividseats.android" />
            <meta name="itunes" content="app-id=963355757" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="format-detection" content="telephone=yes" />
            <meta property="og:site_name" content="Vivid Seats" key="ogsitename" />
            {processedUrl && <meta property="og:url" content={processedUrl} key="ogurl" />}
            {title && <meta property="og:title" content={title} key="ogtitle" />}
            {description && <meta property="og:description" content={description} key="ogdesc" />}
            <link rel="icon" type="image/x-icon" href="//a.vsstatic.com/common/favicon/rebrand/favicon.ico" />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="//a.vsstatic.com/common/favicon/rebrand/apple-touch-icon.png"
            />
        </Head>
    )
}

export default Meta
