import React, { FC } from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const DiscoverIcon: FC<CustomIconProps> = ({ className, fontSize, width = '48px', height = '30px', ...props }) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg {...props} width="100%" height="100%" viewBox="0 0 48 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Discover Icon</title>

            <rect width="48" height="30" rx="3.21" fill="#F1F2F2" />
            <path
                d="M13.1899 30H46.3965C47.2735 30 47.9867 29.2935 47.9949 28.4166V17.1685C44.8082 19.1851 32.8082 26.151 13.1899 30V30Z"
                fill="#F58220"
            />
            <path
                d="M24.5824 12.6622C23.2835 12.64 22.2119 13.6737 22.1874 14.9726C22.1529 15.9482 22.714 16.8472 23.6057 17.2447C24.4974 17.6422 25.5411 17.4588 26.2437 16.781C26.9463 16.1032 27.1672 15.0667 26.8021 14.1613C26.4369 13.2559 25.5587 12.6628 24.5824 12.6622V12.6622Z"
                fill="url(#paint0_radial_6152_107176)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.6991 12.7469H10.9837C12.3929 12.7469 13.3887 13.6681 13.3887 14.9727C13.3967 15.6498 13.1007 16.2949 12.5821 16.7304C12.1274 17.0885 11.5563 17.2658 10.9788 17.2283H9.6991V12.7469ZM10.8093 16.4814C11.2407 16.5228 11.6713 16.3942 12.0093 16.1229C12.331 15.8275 12.512 15.4094 12.5072 14.9727C12.5078 14.5469 12.3266 14.1412 12.0093 13.8573C11.6738 13.5808 11.2418 13.45 10.8093 13.4938H10.5753V16.4814H10.8093Z"
                fill="#001722"
            />
            <path d="M13.802 12.7469H14.8147V17.2382H13.802V12.7469Z" fill="#001722" />
            <path
                d="M16.815 14.4746C16.2872 14.2804 16.1328 14.151 16.1328 13.912C16.1526 13.7591 16.2327 13.6206 16.3552 13.527C16.4777 13.4335 16.6325 13.3929 16.7851 13.4141C17.064 13.4206 17.3245 13.5545 17.4922 13.7775L17.9502 13.18C17.5873 12.8562 17.1171 12.6788 16.6307 12.6821C16.2715 12.6603 15.9189 12.7862 15.6547 13.0307C15.3906 13.2751 15.2377 13.6169 15.2316 13.9767C15.2316 14.5991 15.5154 14.9128 16.337 15.2116C16.5472 15.2765 16.7506 15.3615 16.9444 15.4655C17.1081 15.5624 17.2084 15.7384 17.2083 15.9286C17.2074 16.1048 17.1335 16.2728 17.0043 16.3926C16.8751 16.5124 16.702 16.5733 16.5262 16.5609C16.1175 16.5679 15.743 16.3338 15.5702 15.9634L15.0075 16.5112C15.3312 17.0578 15.926 17.3857 16.561 17.3676C16.9702 17.396 17.3722 17.2497 17.6674 16.965C17.9626 16.6802 18.1233 16.2837 18.1096 15.8738C18.1096 15.1568 17.8158 14.8331 16.815 14.4746V14.4746Z"
                fill="#001722"
            />
            <path
                d="M18.3688 14.9727C18.3633 15.6007 18.6121 16.2042 19.0585 16.6459C19.5049 17.0877 20.111 17.33 20.739 17.3179C21.1215 17.3185 21.4987 17.2281 21.8394 17.054V16.0482C21.5768 16.36 21.1914 16.5419 20.7838 16.5461C20.3779 16.5464 19.9894 16.3815 19.7077 16.0894C19.4259 15.7973 19.2751 15.4032 19.29 14.9976C19.2766 14.5971 19.4246 14.2081 19.7008 13.9178C19.977 13.6276 20.3583 13.4605 20.7589 13.454C21.1816 13.4654 21.5796 13.6554 21.8543 13.9768V12.9212C21.5245 12.741 21.1546 12.6468 20.7788 12.6474C20.1503 12.6341 19.5424 12.872 19.09 13.3086C18.6375 13.7451 18.378 14.3441 18.3688 14.9727V14.9727Z"
                fill="#001722"
            />
            <path
                d="M28.9541 15.7644L27.7541 12.7469H26.7981L28.7051 17.3528H29.1782L31.1151 12.7469H30.169L28.9541 15.7644Z"
                fill="#001722"
            />
            <path
                d="M31.5142 17.2382H34.0038V16.4814H32.3905V15.2665H33.9391V14.5046H32.3905V13.5088H34.0038V12.7469H31.5142V17.2382Z"
                fill="#001722"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.8754 12.7469C36.8861 12.7469 37.4637 13.2299 37.4637 14.0714C37.5127 14.6955 37.0717 15.2515 36.453 15.3461L37.8671 17.2382H36.7915L35.5666 15.4357H35.4521V17.2283H34.5758V12.7469H35.8754ZM35.4519 14.8133H35.7059C36.2685 14.8133 36.5623 14.5594 36.5623 14.1212C36.5623 13.6831 36.2685 13.454 35.7208 13.454H35.4519V14.8133Z"
                fill="#001722"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.6087 13.1551C37.6087 12.9653 37.7626 12.8115 37.9523 12.8115C38.0434 12.8115 38.1308 12.8477 38.1952 12.9122C38.2597 12.9766 38.2959 13.064 38.2959 13.1551C38.2959 13.3448 38.1421 13.4987 37.9523 13.4987C37.7626 13.4987 37.6087 13.3448 37.6087 13.1551ZM37.6828 13.2708C37.7298 13.3765 37.8363 13.443 37.952 13.4389C38.0259 13.4376 38.0963 13.407 38.1477 13.3538C38.199 13.3005 38.2272 13.2291 38.2258 13.1551C38.2257 13.0394 38.1555 12.9354 38.0482 12.8921C37.9409 12.8488 37.8181 12.875 37.7378 12.9582C37.6574 13.0415 37.6357 13.1651 37.6828 13.2708Z"
                fill="#231F20"
            />
            <defs>
                <radialGradient
                    id="paint0_radial_6152_107176"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(26.9789 17.3431) scale(2.36515 2.3653)"
                >
                    <stop offset="0.01" stopColor="#FFEEE0" />
                    <stop offset="0.11" stopColor="#FEE9D7" />
                    <stop offset="0.28" stopColor="#FDDBBF" />
                    <stop offset="0.49" stopColor="#FBC597" />
                    <stop offset="0.74" stopColor="#F8A560" />
                    <stop offset="1" stopColor="#F48120" />
                </radialGradient>
            </defs>
        </svg>
    </SVGWrapper>
)

export default DiscoverIcon
