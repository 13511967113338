import React, { FC } from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const OvernightDeliveryIcon: FC<CustomIconProps> = ({
    className,
    fontSize,
    width = '20px',
    height = '20px',
    ...props
}) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg {...props} width="100%" height="100%" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.08101 11.0494C3.32644 11.6262 4.07442 12.0061 4.90491 12.0061H9.99748L9.37983 14.6166L9.37006 14.6999C9.37003 15.2778 9.66871 15.822 10.1861 16.1222C10.7773 16.4652 11.5307 16.4186 12.0688 16.0157C12.1146 15.9814 12.153 15.9416 12.1825 15.8978L13.1775 14.4212L14.5626 15.6561C15.0169 16.0641 15.7626 16.247 16.4736 16.1354C17.2017 16.021 17.7787 15.6151 17.9272 15.0838L20.9609 5.9057C21.1197 5.34369 20.7843 4.79637 20.1408 4.49986C19.5309 4.21879 18.7452 4.20807 18.1204 4.47165L3.96343 9.49147C3.18655 9.81759 2.82622 10.4506 3.08101 11.0494ZM10.5544 14.7386L11.0734 12.5451L12.3427 13.6769L12.3389 13.6826L11.1869 15.3922C11.0857 15.4474 10.9942 15.4425 10.8938 15.3843C10.7082 15.2766 10.5661 15.0276 10.5544 14.7386ZM10.5405 11.0856L18.0696 5.50007L4.49049 10.315C4.23768 10.4215 4.1361 10.6 4.20428 10.7602C4.28184 10.9425 4.56359 11.0856 4.90454 11.0856H10.5405ZM11.5738 11.5912L15.4555 15.0523C15.6246 15.2041 15.9363 15.2806 16.239 15.2331C16.5245 15.1882 16.726 15.0465 16.7753 14.8715L19.8093 5.69261C19.8274 5.62854 19.8188 5.56236 19.7848 5.49981L11.5738 11.5912Z"
                fill="#1C94A5"
            />
        </svg>
    </SVGWrapper>
)

export default OvernightDeliveryIcon
