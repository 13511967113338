import React, { FC } from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const CreditCardIcon: FC<CustomIconProps> = ({ className, fontSize, width = '32px', height = '20px', ...props }) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg {...props} width="100%" height="100%" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.8926 0H3.10744C1.39125 0 0 1.15619 0 2.58242V17.4176C0 18.8438 1.39125 20 3.10744 20H28.8926C30.6088 20 32 18.8438 32 17.4176V2.58242C32 1.15619 30.6088 0 28.8926 0ZM1.90093 2.58242C1.90093 2.18793 2.29549 1.86813 2.78221 1.86813H29.2205C29.7072 1.86813 30.1018 2.18793 30.1018 2.58242V4.69066C30.0112 4.66759 29.9159 4.65527 29.8175 4.65527H1.90093V2.58242ZM29.8175 6.66232C29.9159 6.66232 30.0112 6.65 30.1018 6.62692V17.4176C30.1018 17.8121 29.7072 18.1319 29.2205 18.1319H2.78221C2.29549 18.1319 1.90093 17.8121 1.90093 17.4176V6.66232H29.8175ZM5.98317 11.7583H15.2095C15.7345 11.7583 16.1601 11.3401 16.1601 10.8242C16.1601 10.3083 15.7345 9.89013 15.2095 9.89013H5.98317C5.45817 9.89013 5.03258 10.3083 5.03258 10.8242C5.03258 11.3401 5.45817 11.7583 5.98317 11.7583ZM5.98317 15.055H11.8545C12.3794 15.055 12.805 14.6368 12.805 14.1209C12.805 13.6051 12.3794 13.1869 11.8545 13.1869H5.98317C5.45817 13.1869 5.03258 13.6051 5.03258 14.1209C5.03258 14.6368 5.45817 15.055 5.98317 15.055Z"
                fill="#A0A2B3"
            />
            <mask id="mask0_1144_24699" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="20">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28.8926 0H3.10744C1.39125 0 0 1.15619 0 2.58242V17.4176C0 18.8438 1.39125 20 3.10744 20H28.8926C30.6088 20 32 18.8438 32 17.4176V2.58242C32 1.15619 30.6088 0 28.8926 0ZM1.90093 2.58242C1.90093 2.18793 2.29549 1.86813 2.78221 1.86813H29.2205C29.7072 1.86813 30.1018 2.18793 30.1018 2.58242V4.69066C30.0112 4.66759 29.9159 4.65527 29.8175 4.65527H1.90093V2.58242ZM29.8175 6.66232C29.9159 6.66232 30.0112 6.65 30.1018 6.62692V17.4176C30.1018 17.8121 29.7072 18.1319 29.2205 18.1319H2.78221C2.29549 18.1319 1.90093 17.8121 1.90093 17.4176V6.66232H29.8175ZM5.98317 11.7583H15.2095C15.7345 11.7583 16.1601 11.3401 16.1601 10.8242C16.1601 10.3083 15.7345 9.89013 15.2095 9.89013H5.98317C5.45817 9.89013 5.03258 10.3083 5.03258 10.8242C5.03258 11.3401 5.45817 11.7583 5.98317 11.7583ZM5.98317 15.055H11.8545C12.3794 15.055 12.805 14.6368 12.805 14.1209C12.805 13.6051 12.3794 13.1869 11.8545 13.1869H5.98317C5.45817 13.1869 5.03258 13.6051 5.03258 14.1209C5.03258 14.6368 5.45817 15.055 5.98317 15.055Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_1144_24699)">
                <rect x="-0.425537" y="-0.03125" width="32.6809" height="20.0705" fill="#474B5E" />
            </g>
        </svg>
    </SVGWrapper>
)

export default CreditCardIcon
