import React from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const FamilyNonAlcIcon: React.FC<CustomIconProps> = ({
    className,
    fontSize,
    width = '24px',
    height = '24px',
    ...props
}) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg
            data-testid="family-non-alc-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            {...props}
        >
            <mask
                id="a"
                width={24}
                height={24}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'alpha',
                }}
            >
                <path fill="#D9D9D9" d="M24 0H0v24h24z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="#1C1B1F"
                    d="m9.565 11.605 1.06-1.029-1.843-2.075h3.902L14.17 7h-6.72l-1.8-2h10.52l1.5-1.5H5.046c-.367 0-.663.125-.889.376a1.22 1.22 0 0 0-.338.836c0 .144.027.284.08.419.055.134.135.264.243.388l5.423 6.085Zm7.185 9.146a.726.726 0 0 0 .534-.215A.726.726 0 0 0 17.5 20a.726.726 0 0 0-.216-.535.726.726 0 0 0-.534-.215h-4v-5.877l8.65-8.65a.715.715 0 0 0 .212-.514.748.748 0 0 0-.212-.54.735.735 0 0 0-.535-.232.735.735 0 0 0-.534.232L3.654 20.347a.733.733 0 0 0-.228.522c-.004.2.072.377.228.532a.735.735 0 0 0 .534.233c.201 0 .38-.078.535-.233l6.511-6.512.016 4.362h-4a.726.726 0 0 0-.535.215.726.726 0 0 0-.215.535c0 .213.072.391.215.535a.726.726 0 0 0 .535.215h9.5Z"
                />
            </g>
        </svg>
    </SVGWrapper>
)

export default FamilyNonAlcIcon
