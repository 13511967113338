import React from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const RestrictedViewIcon: React.FC<CustomIconProps> = ({
    className,
    fontSize,
    width = '24px',
    height = '24px',
    ...props
}) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg
            data-testid="restricted-view-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 -3 24 24"
            {...props}
        >
            <path
                fill="#04092C"
                d="M9.5 7.045c.053 0 .105.01.169.021l1.098-1.08a3.682 3.682 0 0 0-1.257-.228c-1.995 0-3.61 1.557-3.61 3.489 0 .457.095.883.254 1.277l1.098-1.08c0-.062-.022-.135-.022-.197 0-1.215 1.024-2.202 2.28-2.202H9.5ZM9.5 11.438c-.053 0-.105-.01-.158-.021l-1.098 1.08c.39.145.813.228 1.256.228 1.995 0 3.61-1.557 3.61-3.489 0-.446-.095-.872-.253-1.277l-1.098 1.08c0 .062.021.135.021.197 0 1.215-1.024 2.202-2.28 2.202Z"
            />
            <path
                fill="#04092C"
                d="M18.493 7.96a16.38 16.38 0 0 0-3.05-2.544l-.96.945A14.985 14.985 0 0 1 17.5 8.832a.589.589 0 0 1 0 .82c-2.28 2.42-5.34 4.102-7.99 4.06-.708.011-1.436-.103-2.175-.31l-1.034 1.017c1.076.384 2.164.602 3.22.581 3.07.052 6.47-1.817 8.982-4.485a1.863 1.863 0 0 0 0-2.555h-.01ZM6.44 13.099l1.013-.997.96-.945 3.061-3.011.96-.945 1.31-1.288.96-.945.845-.83c.2-.198.169-.55-.064-.78-.232-.228-.59-.259-.791-.062l-1.13 1.112-.992.976-1.013.996-.96.945-3.062 3.012-.96.945-1.31 1.287-.96.945-.844.831c-.2.197-.17.55.063.779.232.228.591.26.792.062l1.119-1.1.992-.977.01-.01ZM4.518 12.123a14.986 14.986 0 0 1-3.02-2.471.59.59 0 0 1 0-.82c2.28-2.43 5.363-4.102 7.991-4.06h.022c.696-.011 1.425.103 2.163.31l1.035-1.017c-1.077-.384-2.164-.602-3.209-.592C6.428 3.432 3.019 5.291.507 7.96a1.863 1.863 0 0 0 0 2.555 16.379 16.379 0 0 0 3.05 2.544l.96-.945v.01Z"
            />
        </svg>
    </SVGWrapper>
)

export default RestrictedViewIcon
