import React from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const VIPIcon: React.FC<CustomIconProps> = ({ className, fontSize, width = '24px', height = '24px', ...props }) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg
            data-testid="vip-icon"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="-3 -3 24 24"
            fill="none"
            {...props}
        >
            <path
                fill="#04092C"
                stroke="#04092C"
                strokeWidth={0.3}
                d="M2.53 15.586H1.285c-.12 0-.23.04-.313.121a.432.432 0 0 0-.122.312c0 .12.04.23.122.312a.435.435 0 0 0 .313.121h3.502c.119 0 .23-.04.312-.121a.432.432 0 0 0 .122-.312c0-.12-.04-.23-.122-.312a.435.435 0 0 0-.312-.121H3.37V6.007c.454.743 1.08 1.633 1.855 2.398.949.937 2.135 1.701 3.512 1.76H8.913c2.004 0 3.941-1.493 5.772-4.33v9.808h-1.473c-.12 0-.23.04-.312.121a.432.432 0 0 0-.123.312c0 .119.041.23.123.311a.435.435 0 0 0 .312.122h3.502c.12 0 .23-.04.313-.122a.432.432 0 0 0 .122-.311.434.434 0 0 0-.435-.433h-1.16V4.605a1.39 1.39 0 0 0 .968-1.33 1.41 1.41 0 0 0-1.402-1.397 1.41 1.41 0 0 0-1.403 1.396c0 .541.309 1.006.762 1.233-.936 1.617-1.88 2.82-2.819 3.613-.966.815-1.92 1.19-2.853 1.15-1.346-.067-2.527-.99-3.455-2.068-.853-.99-1.47-2.085-1.783-2.69.46-.226.797-.694.797-1.266A1.41 1.41 0 0 0 2.965 1.85a1.41 1.41 0 0 0-1.403 1.396c0 .628.422 1.138.968 1.329v11.01ZM2.375 3.274c0-.284.245-.53.533-.53.287 0 .533.246.533.53 0 .285-.246.53-.533.53a.523.523 0 0 1-.533-.53Zm12.184 0c0-.284.246-.53.533-.53.296 0 .562.254.562.53 0 .285-.245.53-.533.53-.3 0-.562-.228-.562-.53Z"
            />
        </svg>
    </SVGWrapper>
)

export default VIPIcon
