import React, { FC } from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const VisaIcon: FC<CustomIconProps> = ({ className, fontSize, width = '48px', height = '30px', ...props }) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg {...props} width="100%" height="100%" viewBox="0 0 48 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Visa Icon</title>

            <rect x="0.5" y="0.5" width="47" height="29" rx="2.5" stroke="#EFEFF6" />
            <path d="M21.9557 18.9909H19.625L21.0798 10.2251H23.4106L21.9557 18.9909Z" fill="#1434CB" />
            <path
                d="M17.661 10.2251L15.4392 16.2564L15.1769 14.9571L14.3951 11.0154C14.3018 10.5054 13.8158 10.1574 13.2915 10.2251H9.61485L9.57031 10.3754C10.4293 10.5866 11.2503 10.925 12.005 11.379L14.0338 18.9909H16.4635L20.1699 10.2202H17.661V10.2251Z"
                fill="#1434CB"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.1285 18.9909H35.9908L35.7335 17.6867H32.7644L32.2695 18.9909H29.8398L33.3137 10.8796C33.4892 10.4544 33.9257 10.1895 34.3924 10.2251H36.2629L38.1285 18.9909ZM34.6246 12.6105L33.4023 15.8928H35.3124L34.6246 12.6105Z"
                fill="#1434CB"
            />
            <path
                d="M30.0226 12.3294L30.3541 10.4482C29.6816 10.2124 28.975 10.0831 28.2609 10.0652C27.103 10.0652 24.3566 10.55 24.3566 12.9742C24.3566 15.2384 27.5781 15.2674 27.5781 16.4553C27.5781 17.6431 24.6882 17.425 23.7331 16.6832L23.3867 18.6516C24.2206 18.9898 25.117 19.1549 26.0193 19.1364C27.6077 19.1364 30.0077 18.3316 30.0077 16.1353C30.0077 13.8566 26.7566 13.6433 26.7566 12.6493C26.7566 11.6554 29.028 11.7912 30.0226 12.3294Z"
                fill="#1434CB"
            />
            <path
                d="M15.1769 14.9571L14.3951 11.0154C14.3018 10.5054 13.8158 10.1574 13.2915 10.2251H9.61485L9.57031 10.3754C10.837 10.6904 12.0189 11.271 13.0342 12.0772C13.9956 12.8348 14.7349 13.8285 15.1769 14.9571Z"
                fill="#1434CB"
            />
        </svg>
    </SVGWrapper>
)

export default VisaIcon
