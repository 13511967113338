import React, { FC } from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const SeatSaverWithContainerIcon: FC<CustomIconProps> = ({
    className,
    fontSize,
    width = '24px',
    height = '24px',
    ...props
}) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg
            data-testid="seat-saver-icon"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="-3 -3 24 24"
            fill="none"
            {...props}
        >
            <path
                fill="#4AC76D"
                fillRule="evenodd"
                d="M9 18A9 9 0 1 0 9 0a9 9 0 0 0 0 18Zm4.305-12.678C13.15 4.284 12.276 3.5 11.229 3.5H6.772c-1.048 0-1.92.784-2.076 1.822l-.353 2.71a1.702 1.702 0 0 0-.144-.007c-.952 0-1.7.79-1.7 1.735a4.233 4.233 0 0 0 1.97 3.59l-.015.994a.411.411 0 1 0 .822.014l.01-.617c.412.143.853.22 1.313.221h4.8c.46 0 .902-.078 1.314-.22l.01.616a.411.411 0 0 0 .823-.013l-.016-.995A4.233 4.233 0 0 0 15.5 9.76c0-.945-.748-1.735-1.7-1.735-.048 0-.095.002-.142.006l-.352-2.7-.001-.01Zm.154 3.797a.671.671 0 0 1 .341-.094c.373 0 .7.315.7.734-.002 1.782-1.402 3.202-3.1 3.203H6.6c-1.698-.001-3.098-1.421-3.1-3.203 0-.418.327-.734.7-.734.373 0 .7.316.7.735 0 .944.749 1.733 1.7 1.734h4.8c.952-.001 1.7-.79 1.7-1.734 0-.282.148-.518.359-.641Zm-.757-.684-.387-2.97C12.23 4.9 11.76 4.5 11.23 4.5H6.773c-.53 0-1 .4-1.086.965L5.3 8.437c.369.32.6.797.6 1.323 0 .418.327.733.7.734h4.8c.373 0 .7-.316.7-.735 0-.526.232-1.004.602-1.324Z"
                clipRule="evenodd"
            />
        </svg>
    </SVGWrapper>
)

export default SeatSaverWithContainerIcon
