import React from 'react'

import { Icon, Text } from '@vividseats/vivid-ui-kit'

import styles from './styles.module.scss'

const ErrorMessage: React.FC = () => {
    return (
        <div data-testid="header-error-message" className={styles.errorContainer}>
            <div className={styles.contentRow}>
                <div className={styles.contentColumn}>
                    <Icon className={styles.errorIcon} size="md" type="state-warning" />
                </div>
                <div className={styles.contentColumn}>
                    <Text altFont weight="bold" className={styles.errorTitleText}>
                        Something went wrong
                    </Text>
                    <Text altFont className={styles.errorBodyText}>
                        Please try again to load
                    </Text>
                </div>
            </div>
        </div>
    )
}

export default ErrorMessage
