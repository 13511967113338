import React from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const DoorOpenIcon: React.FC<CustomIconProps> = ({
    className,
    fontSize,
    width = '24px',
    height = '24px',
    ...props
}) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg
            data-testid="door-open-icon"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height="100%"
            fill="none"
            {...props}
        >
            <mask
                id="a"
                width={width}
                height={height}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'alpha',
                }}
            >
                <path fill="#D9D9D9" d="M0 0h24v24H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="#1C1B1F"
                    d="M11 12.884a.848.848 0 0 0 .63-.258.862.862 0 0 0 .254-.626.856.856 0 0 0-.254-.63.856.856 0 0 0-.63-.254.861.861 0 0 0-.626.253.848.848 0 0 0-.259.631c0 .245.086.454.259.626a.853.853 0 0 0 .626.258ZM7 20.5V19l6.5-.846V6.664c0-.315-.1-.591-.297-.83a1.095 1.095 0 0 0-.743-.407L7.307 4.75v-1.5l5.366.708a2.661 2.661 0 0 1 1.67.91A2.62 2.62 0 0 1 15 6.637V19.42L7 20.5Zm-3.27 0V19H5.5V5.058c0-.516.176-.946.53-1.29.353-.346.779-.518 1.277-.518h9.385c.515 0 .945.172 1.29.517.345.345.518.775.518 1.29V19h1.769v1.5H3.73ZM7 19h10V5.058a.3.3 0 0 0-.087-.221.3.3 0 0 0-.221-.087H7.307a.3.3 0 0 0-.22.087.3.3 0 0 0-.087.22V19Z"
                />
            </g>
        </svg>
    </SVGWrapper>
)

export default DoorOpenIcon
