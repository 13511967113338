import React, { FC } from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const PaypalBadgeIcon: FC<CustomIconProps> = ({ className, fontSize, width = '33px', height = '20px', ...props }) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 33 20" fill="none">
            <path
                d="M2.42739 0H30.0726C31.4132 0 32.5 1.0674 32.5 2.38411V17.6159C32.5 18.9326 31.4132 20 30.0726 20H2.42739C1.08678 20 0 18.9326 0 17.6159V2.38411C0 1.0674 1.08678 0 2.42739 0Z"
                fill="#F0F1F5"
            />
            <path
                d="M15.309 6.83594H18.8143C20.6961 6.83594 21.4047 7.80839 21.2953 9.23928C21.1149 11.5983 19.7176 12.9027 17.8644 12.9027H16.9287C16.6748 12.9027 16.5039 13.0741 16.4347 13.5398L16.0333 16.244C16.0072 16.4198 15.9167 16.5229 15.7807 16.5354H13.5828C13.376 16.5354 13.3024 16.3739 13.3565 16.024L14.6987 7.34913C14.7525 7.00152 14.9389 6.83594 15.309 6.83594Z"
                fill="#00A3DB"
            />
            <path
                d="M13.1173 3.85156H16.626C17.6143 3.85156 18.7863 3.88435 19.5705 4.59019C20.0942 5.06173 20.3691 5.81301 20.3056 6.61951C20.0904 9.35439 18.4879 10.8862 16.3387 10.8862H14.6082C14.3137 10.8862 14.1184 11.0854 14.0354 11.625L13.5524 14.7607C13.5211 14.9642 13.4347 15.084 13.277 15.0985H11.1156C10.8758 15.0985 10.7905 14.9112 10.8533 14.5056L12.4097 4.44658C12.472 4.04347 12.6881 3.85156 13.1173 3.85156Z"
                fill="#183B77"
            />
            <path
                d="M14.0852 11.3079L14.698 7.34913C14.7517 7.00143 14.9381 6.83594 15.3082 6.83594H18.8136C19.3938 6.83594 19.8626 6.92838 20.23 7.09886C19.8778 9.53277 18.3356 10.8853 16.316 10.8853H14.5854C14.3576 10.8853 14.1874 11.004 14.0852 11.3079Z"
                fill="#152A60"
            />
        </svg>
    </SVGWrapper>
)

export default PaypalBadgeIcon
