import React, { FC } from 'react'

import { Link, Text } from '@vividseats/vivid-ui-kit'
import NextLink from 'next/link'

import { PATHS } from '@/constants'

export interface DOADisclaimerProps {
    className?: string
}

export const DOADisclaimer: FC<DOADisclaimerProps> = ({ className }) => (
    <Text as="span" className={className} weight="regular" altFont data-testid="doa-disclaimer">
        Please note, resale of tickets for this event is prohibited. In some cases, customers have been refused entry to
        similar events. <br />
        As a Vivid Seats fan, you will be protected by our&nbsp;
        <Link LinkComponent={NextLink} href={PATHS.GUARANTEE} target="_blank" legacyBehavior>
            Buyer Guarantee
        </Link>{' '}
        should you be refused entry.
    </Text>
)

export default DOADisclaimer
