import React, { FC } from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const MastercardIcon: FC<CustomIconProps> = ({ className, fontSize, width = '48px', height = '30px', ...props }) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg {...props} width="100%" height="100%" viewBox="0 0 48 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Mastercard Icon</title>

            <path
                d="M3.21 0.5H44.79C46.2867 0.5 47.5 1.71331 47.5 3.21V26.79C47.5 28.2867 46.2867 29.5 44.79 29.5H3.21C1.71331 29.5 0.5 28.2867 0.5 26.79V3.21C0.5 1.71331 1.71331 0.5 3.21 0.5Z"
                stroke="#EFEFF6"
            />
            <path d="M27.9248 8.42505H19.8823V22.575H27.9248V8.42505Z" fill="#FF5F00" />
            <path
                d="M20.3929 15.5C20.3929 12.75 21.695 10.125 23.8908 8.42501C19.9078 5.35001 14.112 6.02501 10.9716 9.95001C7.85671 13.85 8.54608 19.5 12.5546 22.575C15.8993 25.15 20.5716 25.15 23.9163 22.575C21.695 20.875 20.3929 18.25 20.3929 15.5Z"
                fill="#EB001B"
            />
            <path
                d="M38.7759 15.5C38.7759 20.475 34.6653 24.5 29.5844 24.5C27.5163 24.5 25.5248 23.825 23.9163 22.575C27.8993 19.5 28.5887 13.85 25.4482 9.92501C24.9887 9.37501 24.478 8.85001 23.9163 8.42501C27.8993 5.35001 33.695 6.02501 36.8099 9.95001C38.0865 11.525 38.7759 13.475 38.7759 15.5Z"
                fill="#F79E1B"
            />
        </svg>
    </SVGWrapper>
)

export default MastercardIcon
