import React, { FC } from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const FlameIcon: FC<CustomIconProps> = ({
    className,
    fontSize,
    width = '18px',
    height = '17px',
    color = '#8F1564',
    ...props
}) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg width="100%" height="100%" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
            <title>Flame Icon</title>

            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.80217 3.12452C7.63524 2.93885 7.60096 2.66912 7.71616 2.44761C7.83137 2.22609 8.07188 2.09926 8.32037 2.1294C11.4463 2.51352 14.4255 5.97679 14.4255 9.28398C14.4255 12.4515 12.4199 14.875 9.23385 14.875C6.5068 14.875 4.04219 12.8757 4.04225 10.4887C4.01091 8.60189 5.166 6.89801 6.92118 6.2321C7.11504 6.15304 7.33612 6.18092 7.50428 6.30566C7.67243 6.43039 7.76327 6.63386 7.74221 6.85707C7.64738 7.58242 7.92542 8.30657 8.47992 8.78087C9.85313 7.51462 10.0893 5.67246 7.80217 3.12452ZM11.4152 9.6986C11.4067 9.4782 11.5786 9.2927 11.799 9.28427C12.0194 9.27585 12.2049 9.44768 12.2133 9.66808C12.2703 11.1596 11.1103 12.4163 9.61897 12.4785C9.39861 12.4877 9.21251 12.3165 9.20332 12.0961C9.19412 11.8758 9.36531 11.6897 9.58568 11.6805C10.6373 11.6366 11.4554 10.7504 11.4152 9.6986ZM9.23385 14.0763C6.91542 14.0763 4.84091 12.3934 4.84091 10.4821C4.81687 9.03133 5.63931 7.71687 6.92444 7.09935C6.91112 7.96616 7.28711 8.81188 7.96262 9.38944C8.27152 9.65111 8.72495 9.64151 9.02116 9.36825C10.612 7.90154 10.9626 5.72166 8.76884 3.02596C11.291 3.67334 13.6268 6.57036 13.6268 9.28398C13.6268 12.0403 11.9418 14.0763 9.23385 14.0763Z"
                fill={color}
            />
            <mask id="mask0_7357_51279" maskUnits="userSpaceOnUse" x="4" y="2" width="11" height="13">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.80217 3.12452C7.63524 2.93885 7.60096 2.66912 7.71616 2.44761C7.83137 2.22609 8.07188 2.09926 8.32037 2.1294C11.4463 2.51352 14.4255 5.97679 14.4255 9.28398C14.4255 12.4515 12.4199 14.875 9.23385 14.875C6.5068 14.875 4.04219 12.8757 4.04225 10.4887C4.01091 8.60189 5.166 6.89801 6.92118 6.2321C7.11504 6.15304 7.33612 6.18092 7.50428 6.30566C7.67243 6.43039 7.76327 6.63386 7.74221 6.85707C7.64738 7.58242 7.92542 8.30657 8.47992 8.78087C9.85313 7.51462 10.0893 5.67246 7.80217 3.12452ZM11.4152 9.6986C11.4067 9.4782 11.5786 9.2927 11.799 9.28427C12.0194 9.27585 12.2049 9.44768 12.2133 9.66808C12.2703 11.1596 11.1103 12.4163 9.61897 12.4785C9.39861 12.4877 9.21251 12.3165 9.20332 12.0961C9.19412 11.8758 9.36531 11.6897 9.58568 11.6805C10.6373 11.6366 11.4554 10.7504 11.4152 9.6986ZM9.23385 14.0763C6.91542 14.0763 4.84091 12.3934 4.84091 10.4821C4.81687 9.03133 5.63931 7.71687 6.92444 7.09935C6.91112 7.96616 7.28711 8.81188 7.96262 9.38944C8.27152 9.65111 8.72495 9.64151 9.02116 9.36825C10.612 7.90154 10.9626 5.72166 8.76884 3.02596C11.291 3.67334 13.6268 6.57036 13.6268 9.28398C13.6268 12.0403 11.9418 14.0763 9.23385 14.0763Z"
                />
            </mask>
            <g mask="url(#mask0_7357_51279)"></g>
        </svg>
    </SVGWrapper>
)

export default FlameIcon
