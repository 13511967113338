import React from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const StandingRoomIcon: React.FC<CustomIconProps> = ({
    className,
    fontSize,
    width = '24px',
    height = '24px',
    ...props
}) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg
            data-testid="standing-room-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            {...props}
        >
            <mask
                id="a"
                width={24}
                height={24}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'alpha',
                }}
            >
                <path fill="#D9D9D9" d="M0 0h24v24H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="#1C1B1F"
                    d="M9.404 21V8.552a5.77 5.77 0 0 1-3.166-1.916 6.591 6.591 0 0 1-1.534-3.4c-.027-.175.034-.326.183-.452a.776.776 0 0 1 .517-.19c.203 0 .379.057.527.17.149.112.24.265.273.457.203 1.149.723 2.1 1.56 2.856.837.755 1.833 1.133 2.987 1.133h2.5c.442 0 .838.074 1.188.222.35.148.68.378.992.69l3.854 3.853c.139.138.21.313.212.522a.706.706 0 0 1-.212.532.718.718 0 0 1-.527.217.718.718 0 0 1-.527-.217l-3.635-3.635V21a.726.726 0 0 1-.215.535.726.726 0 0 1-.534.215.726.726 0 0 1-.535-.215.726.726 0 0 1-.215-.535v-5.192h-2.193v5.192a.726.726 0 0 1-.215.535.726.726 0 0 1-.535.215.726.726 0 0 1-.534-.215.726.726 0 0 1-.216-.535Zm2.597-15.116a1.78 1.78 0 0 1-1.307-.54 1.78 1.78 0 0 1-.54-1.306c0-.512.18-.947.54-1.307.36-.36.795-.54 1.306-.54.512 0 .948.18 1.307.54.36.36.54.795.54 1.307 0 .511-.18.947-.54 1.306-.36.36-.795.54-1.306.54Z"
                />
            </g>
        </svg>
    </SVGWrapper>
)

export default StandingRoomIcon
