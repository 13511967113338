import React from 'react'

import { LazyBackgroundImage } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'

import styles from './styles.module.scss'

interface Props {
    className?: string
    flag?: string
}

const CountryFlag: React.FC<Props> = ({ className, flag }) => {
    const mergedClassName = classNames(styles.defaultFlagStyle, className)
    return <>{flag && <LazyBackgroundImage url={flag} className={mergedClassName} />}</>
}

export default CountryFlag
