import React, { FC } from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const InstantDeliveryWithContainerIcon: FC<CustomIconProps> = ({
    className,
    fontSize,
    width = '20px',
    height = '20px',
    ...props
}) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg {...props} width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM16.2003 10.4263C16.2983 10.4263 16.3882 10.481 16.4333 10.568C16.4785 10.6555 16.471 10.7608 16.4139 10.841L11.1647 18.1898C11.0922 18.2916 10.958 18.3281 10.8439 18.2771C10.7298 18.2261 10.6675 18.1018 10.6949 17.9798L11.6734 13.5758H7.8016C7.70265 13.5763 7.61183 13.5211 7.56669 13.4331C7.52155 13.345 7.52978 13.2391 7.58796 13.159L12.8371 5.81019C12.9096 5.70841 13.0439 5.67187 13.158 5.72287C13.2721 5.77386 13.3344 5.89824 13.3069 6.02016L12.3285 10.4263H16.2003Z"
                fill="#4AC76D"
            />
        </svg>
    </SVGWrapper>
)

export default InstantDeliveryWithContainerIcon
