import React from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import type { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const ClubSeatsIcon: React.FC<CustomIconProps> = ({
    className,
    fontSize,
    width = '24px',
    height = '24px',
    ...props
}) => (
    <SVGWrapper className={className} fontSize={fontSize} width={width} height={height}>
        <svg
            data-testid="club-seats-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            {...props}
        >
            <mask
                id="a"
                width={24}
                height={24}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'alpha',
                }}
            >
                <path fill="#D9D9D9" d="M0 0h24v24H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="#1C1B1F"
                    d="m12 13.65 1.25.967c.17.137.351.142.542.013.191-.13.252-.3.181-.515l-.504-1.592 1.444-1.117a.428.428 0 0 0 .14-.51.415.415 0 0 0-.426-.304h-1.662l-.538-1.646c-.07-.214-.213-.321-.427-.321s-.356.107-.427.321l-.538 1.646H9.348c-.214 0-.354.102-.42.304a.438.438 0 0 0 .149.51l1.404 1.117-.504 1.627c-.07.214-.017.386.16.514.178.13.357.125.538-.012L12 13.65ZM4.308 19.5c-.499 0-.925-.177-1.278-.53a1.741 1.741 0 0 1-.53-1.278v-2.74a.713.713 0 0 1 .52-.696 2.603 2.603 0 0 0 1.068-.908c.275-.401.412-.85.412-1.348 0-.498-.137-.947-.412-1.348a2.602 2.602 0 0 0-1.069-.908.713.713 0 0 1-.52-.696v-2.74c.001-.499.178-.925.53-1.278.354-.353.78-.53 1.279-.53h15.384c.499 0 .925.177 1.278.53.353.353.53.779.53 1.278v2.74a.713.713 0 0 1-.52.696 2.602 2.602 0 0 0-1.069.908c-.274.401-.411.85-.411 1.348 0 .497.137.947.412 1.348.274.401.63.704 1.069.908a.713.713 0 0 1 .52.696v2.74c-.001.499-.178.925-.53 1.278-.354.353-.78.53-1.279.53H4.308Zm0-1.5h15.384a.3.3 0 0 0 .221-.087.3.3 0 0 0 .087-.22V15.45a4.1 4.1 0 0 1-1.462-1.462A3.846 3.846 0 0 1 18 12c0-.717.18-1.38.538-1.988A4.099 4.099 0 0 1 20 8.55V6.308a.3.3 0 0 0-.087-.221.3.3 0 0 0-.22-.087H4.307a.3.3 0 0 0-.221.087.3.3 0 0 0-.087.22V8.55a4.1 4.1 0 0 1 1.462 1.462C5.821 10.621 6 11.284 6 12c0 .717-.18 1.38-.538 1.988A4.1 4.1 0 0 1 4 15.45v2.242a.3.3 0 0 0 .087.221.3.3 0 0 0 .22.087Z"
                />
            </g>
        </svg>
    </SVGWrapper>
)

export default ClubSeatsIcon
