import { useContext } from 'react'

import { I18n, useI18n } from '@shopify/react-i18n'
import isNumber from 'lodash/isNumber'

import InternationalizationContext from '@/context/internationalization'
import { DEFAULT_CURRENCY, ROUNDED_CURRENCIES, DEFAULT_CURRENCY_SYMBOL } from '@/context/internationalization/constants'
import type { I18N } from '@/context/listing/types'

/**
 *
 * @param i18n
 * @param amount
 * @param currency
 * @param shouldShowCurrencyCode
 * @param precision
 * @returns Formatted Currency
 * Utility to format currencies which is powered by '@shopify/react-i18n'
 * There are some extra logic which is accounted in formatting as below:
 * 1- For Japan Yen currency, amount should be always rounded up and without decimal point.
 * 2- Given shouldShowCurrencyCode is provided, we only display currency code when symbol is $ to distinguish between currencies,
 * otherwise only the symbol is sufficient. eg: CHF 64 vs $64 CAD
 */

export const getFormattedCurrency = (
    i18n: I18n,
    amount: number | undefined,
    currency: string | undefined,
    shouldShowCurrencyCode: boolean = false,
    precision: number = 2,
): string => {
    const finalCurrency = currency || DEFAULT_CURRENCY
    const currencySymbol = i18n.getCurrencySymbol(finalCurrency).symbol
    const form = shouldShowCurrencyCode && currencySymbol === DEFAULT_CURRENCY_SYMBOL ? 'explicit' : 'short'

    if (!isNumber(amount)) return `$${amount}` // to retain the current experience in case number is undefined we will show $undefined
    const finalAmount = ROUNDED_CURRENCIES.includes(finalCurrency) ? Math.ceil(amount) : amount
    const finalPrecision = ROUNDED_CURRENCIES.includes(finalCurrency) ? 0 : precision

    return i18n.formatCurrency(finalAmount, { form, currency: finalCurrency, precision: finalPrecision })
}

export const getListingPrice = (i18n: I18n, i18nListingsData: I18N | undefined, price: number | undefined) => {
    if (!price) return
    const currency = i18nListingsData?.applyCurrencyConversion ? i18nListingsData?.to : undefined
    const precision = !!currency || Number.isSafeInteger(price) ? 0 : 2
    const priceToFormat = precision === 0 ? Math.ceil(price) : price
    return getFormattedCurrency(i18n, priceToFormat, currency, i18nListingsData?.applyCurrencyConversion, precision)
}

export const getCurrencySymbol = (currencyCode: string, locale?: string): string => {
    return Intl.NumberFormat(locale || 'en-US', {
        style: 'currency',
        currency: currencyCode || 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })
        .format(0)
        .replace(/\d/g, '')
        .trim()
}

export const useGetCurrencySymbol = () => {
    const {
        selectors: { selectedCurrency },
    } = useContext(InternationalizationContext)
    const [i18n] = useI18n()

    return i18n.getCurrencySymbol(selectedCurrency).symbol
}
