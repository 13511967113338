import { useContext } from 'react'

import UserLocationContext from '../../context'

/**
 *
 * @param countriesArray array of countries where the feature should be hidden
 * @returns boolean
 */
export const useIsCountryFeatured = (countriesArray: string[]): boolean => {
    const {
        selectors: { detectedCountryName },
    } = useContext(UserLocationContext)

    if (!detectedCountryName) return false

    return countriesArray.includes(detectedCountryName)
}
