import React, { FC } from 'react'

import { SVGWrapper } from '@vividseats/vivid-ui-kit'
import { CustomIconProps } from '@vividseats/vivid-ui-kit/dist/components/custom-icons/types'

const ClockIcon: FC<CustomIconProps> = ({ className, fontSize, width, height, color = '#000', ...props }) => (
    <SVGWrapper data-testid="clock-icon" className={className} fontSize={fontSize} width={width} height={height}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="100%"
            height="100%"
            viewBox="0 0 14 14"
            color={color}
            {...props}
        >
            <path
                fill="#086E88"
                fillRule="evenodd"
                d="M7 0a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7Zm0 12.833A5.833 5.833 0 1 1 12.833 7 5.84 5.84 0 0 1 7 12.833Zm.292-6.09 2.703 2.48a.583.583 0 0 1 .036.823.59.59 0 0 1-.824.036L6.314 7.43A.583.583 0 0 1 6.125 7V3.792a.583.583 0 1 1 1.167 0v2.952Z"
                clipRule="evenodd"
            />
        </svg>
    </SVGWrapper>
)

export default ClockIcon
